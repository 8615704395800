import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

const MenuContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MenuItem = styled.li`
  transition: color 0.3s;
  font-weight: 600;

  ${({ theme: { colors, pageMargins, fontSize } }) => `
    font-size: ${fontSize?.mainMenu}px;
    color: ${colors?.black};
    padding: 0 ${pageMargins?.xs}px;

    &:hover {
      color: ${colors?.webBlue}
    }
  `}
`

const StyledLink = styled(Link)`
  &.active > * {
    ${({ theme: { colors } }) => `
      color: ${colors?.webBlue};
    `}
  }
`

const DesktopNavigation = ({ menuItems }) => (
  <nav>
    <MenuContainer>
      {menuItems.map(({ title, url }) => (
        <StyledLink
          to={url}
          key={title}
          activeClassName="active"
          partiallyActive
        >
          <MenuItem>{title}</MenuItem>
        </StyledLink>
      ))}
    </MenuContainer>
  </nav>
)

DesktopNavigation.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default DesktopNavigation
