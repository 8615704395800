import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Icon from '../Icon'
import Modal from '../Modal'
import { HeaderBar, HeaderContainer } from '../../pageLayout/Header/HeaderBar'
import MainWrapper from '../../pageLayout/MainWrapper'

const Hamburger = styled.button`
  border: none;
  background: transparent;
`

const MenuContainer = styled.ul`
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuItem = styled.li`
  transition: color 0.3s;
  font-weight: 600;

  ${({ theme: { colors, pageMargins, fontSize } }) => `
    font-size: ${fontSize?.mainMenuMobile}px;
    color: ${colors?.black};
    padding: ${pageMargins?.xs}px ${pageMargins?.xs}px;

    &:hover {
      color: ${colors?.beige}
    }
  `}
`

const StyledLink = styled(Link)`
  &.active > * {
    ${({ theme: { colors } }) => `
      color: ${colors?.lightBeige};
    `}
  }
`

const CrossIcon = styled.div`
  transform: rotate(45deg);
`

const SecondaryNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.7;

  ${({ theme: { colors, pageMargins } }) => `
    color: ${colors?.lightBeige};
    margin-top: ${pageMargins?.lg}px;
  `}
`

const RSS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme: { pageMargins } }) => `
    margin-top: ${pageMargins?.xs}px;
  `}
`

const IconLink = styled.a`
  height: 24px;
  ${({ theme: { pageMargins } }) => `
    margin: ${pageMargins?.xs}px;
  `}
`

const MobileNavigation = ({ logoImage, menuItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <Hamburger type="button" onClick={() => setIsMenuOpen(true)}>
        <Icon name="hamburger" fill="black" />
      </Hamburger>
      {isMenuOpen && (
        <Modal background="webBlue">
          <HeaderBar>
            <HeaderContainer>
              <Link to="/">
                <Img
                  fluid={logoImage.fluid}
                  // alt={siteTitle}
                  style={{ width: '80px' }}
                />
              </Link>
              <Hamburger type="button" onClick={() => setIsMenuOpen(false)}>
                <CrossIcon>
                  <Icon name="cross" fill="black" />
                </CrossIcon>
              </Hamburger>
            </HeaderContainer>
          </HeaderBar>
          <MainWrapper>
            <NavigationContainer>
              <nav>
                <MenuContainer>
                  {menuItems.map(({ title, url }) => (
                    <StyledLink
                      to={url}
                      key={title}
                      activeClassName="active"
                      partiallyActive
                    >
                      <MenuItem>{title}</MenuItem>
                    </StyledLink>
                  ))}
                </MenuContainer>
              </nav>
              <SecondaryNav>
                <p>Email: studiocastan@gmail.com</p>
                <RSS>
                  <IconLink
                    href="https://www.linkedin.com/in/mcpobla/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      name="linkedin"
                      fill="lightGrey"
                      height={24}
                      width={24}
                    />
                  </IconLink>
                  <IconLink
                    href="https://www.instagram.com/studiocastan/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      name="instagram"
                      fill="lightGrey"
                      height={24}
                      width={24}
                    />
                  </IconLink>
                </RSS>
              </SecondaryNav>
            </NavigationContainer>
          </MainWrapper>
        </Modal>
      )}
    </>
  )
}

MobileNavigation.propTypes = {
  logoImage: PropTypes.object.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default MobileNavigation
