import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ModalContainer = styled.div`
  ${({ theme: { colors, pageMargins }, background }) => `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: ${pageMargins?.sm}px;
    background-color: ${colors[background] || background};
    z-index: 101; 
    width: 100vw;
    height: 100vh;
  `}
`

const Modal = ({ background, children }) => (
  <ModalContainer background={background}>{children}</ModalContainer>
)

Modal.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node,
}

Modal.defaultTypes = {
  background: 'white',
  children: null,
}

export default Modal
