import React from 'react'
import styled from 'styled-components'
import { FOOTER_HEIGHT } from '../../../theme/constants'
import Icon from '../../atoms/Icon'

const FooterContainer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${FOOTER_HEIGHT}px;

  ${({ theme: { colors } }) => `
    background: ${colors?.webBlue};
  `}
`

const IconLink = styled.a`
  height: 24px;
  ${({ theme: { pageMargins } }) => `
    margin: ${pageMargins?.xs}px;
  `}
`

const Footer = () => (
  <FooterContainer>
    <IconLink
      href="https://www.linkedin.com/in/mcpobla/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="linkedin" fill="lightGrey" height={24} width={24} />
    </IconLink>
    <IconLink
      href="https://www.instagram.com/studiocastan/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="instagram" fill="lightGrey" height={24} width={24} />
    </IconLink>
  </FooterContainer>
)

export default Footer
